import React from 'react';
import { BrowserRouter} from 'react-router-dom';
import Home from './Home';
import Certificates from './Certificates';
import Contact from './Contact';
// import New from './new';
import Nav from './Nav';
import Skills from './new';

function App() {
  return (
    <BrowserRouter>
      <Nav />
      <main>
        <section id="home">
          <Home />
        </section>
        <section id="certificates">
          <Certificates />
        </section>
        <section id="contact">
          <Contact />
        </section>
        <section id="new">
          <Skills />
        </section>
      </main>
    </BrowserRouter>
  );
}

export default App;


