import React from "react";
import img2 from './certificates/sid.jpeg';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import './contact.css'; 

export default function Contact() {
    return (
        <div className="contact-container">
            <div className="left-side1">
                <img src={img2} alt="Profile" className="profile-img" />
            </div>
            <div className="right-side1">
                <ul>
                    <li className="li">
                        <span className="address-logo">
                            <i className="fab fa-linkedin"></i>
                        </span>
                        <p>
                            <a href="https://www.linkedin.com/in/siddardha-ladi-852806285/" target="_blank" rel="noopener noreferrer">
                                Siddardha Ladi
                                <span className="address-logo-tick">
                                    <i className="fas fa-check-circle"></i>
                                </span>
                            </a>
                        </p>
                    </li>
                    <li className="li">
                        <span className="address-logo">
                            <i className="fas fa-phone-alt"></i>
                        </span>
                        <p>+91 8179263599</p>
                    </li>
                    <li className="li">
                        <span className="address-logo">
                            <i className="far fa-envelope"></i>
                        </span>
                        <p>siddardhaladi26@gmail.com</p>
                    </li>
                    <li className="li">
                        <span className="address-logo">
                            <i className="fab fa-github"></i>
                        </span>
                        <p><a href="https://github.com/siddardhaladi" target="_blank" rel="noopener noreferrer"> Git Hub </a> </p>
                    </li>
                </ul>
            </div>
        </div>
    );
}

