import React from 'react';
import { Link } from 'react-scroll';
import './Nav.css'; // Import the CSS file

export default function Nav() {
    return (
        <div className='complete'>
            <nav className="nav-bar">
                <Link to="home" smooth={true} duration={500} className="nav-link">
                    <i className="fas fa-home"></i> {/* FontAwesome icon for Home */}
                    <span className="nav-text">Home</span>
                </Link>
                <Link to="certificates" smooth={true} duration={500} className="nav-link">
                    <i className="fas fa-certificate"></i> {/* FontAwesome icon for Certifications */}
                    <span className="nav-text">Certifications</span>
                </Link>
                <Link to="contact" smooth={true} duration={500} className="nav-link">
                    <i className="fa-solid fa-phone"></i> {/* FontAwesome icon for Contact */}
                    <span className="nav-text">Contact</span>
                </Link>
                <Link to="new" smooth={true} duration={500} className="nav-link">
                    <i className="fas fa-lightbulb"></i> {/* FontAwesome icon for Skills */}
                    <span className="nav-text">Skills</span>
                </Link>
            </nav>
        </div>
    );
}






