import React from "react";
import image1 from './certificates/certificate2.jpeg';
import image2 from './certificates/certificate3.jpeg';
import image3 from './certificates/certificate4.jpeg';
import image4 from './certificates/certificate5.jpeg';
import image5 from './certificates/certificate6.jpeg';
import image6 from './certificates/certificate1.jpeg';
import './certificate.css'; 

export default function Certificates() {
    const items = [
        { id: "Certificate1", url: image1 },
        { id: "Certificate2", url: image2 },
        { id: "Certificate3", url: image3 },
        { id: "Certificate4", url: image4 },
        { id: "Certificate5", url: image5 },
        { id: "Certificate6", url: image6 }
    ];

    return (
        <div className="all">
            <center><h1>Certificates</h1></center>
            <div className="space">
                {items.map((item) => (
                    <img  src={item.url} key={item.id} alt={item.id} className="single" />
                ))}
            </div>
        </div>
    );
}