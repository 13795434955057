import React from "react";
import './new.css';

export default function Skills() {
    return (
        <div className="comp">
        <div className="fill">
        <div className="project">
            <h2>Project</h2>
            <a href="https://66c6e7aeb6a72716c84311b7--tiny-truffle-cb2e1c.netlify.app/"><img src="https://img.freepik.com/premium-vector/food-store-logo-template-design_316488-1625.jpg" className="project-image" alt="Foodstore" /></a>
        </div>
        <div className="project">
            <h2>My Number of Clients</h2>
            <img src="https://cdn.icon-icons.com/icons2/2796/PNG/512/number_one_icon_178232.png" className="project-image" alt="Foodstore" />
        </div>
        </div>
        <div className="skills-container">
            <div className="skills1">
                <h2>My Skills</h2>
                <ul>
                    <li className="html-skill">
                        <h3>HTML</h3>
                        <span className="bar"></span>
                    </li>
                    <li className="css-skill">
                        <h3>CSS</h3>
                        <span className="bar"></span>
                    </li>
                    <li className="js-skill">
                        <h3>JS</h3>
                        <span className="bar"></span>
                    </li>
                    <li className="react-skill">
                        <h3>React</h3>
                        <span className="bar"></span>
                    </li>
                    <li className="IOT-skill">
                        <h3>IOT</h3>
                        <span className="bar"></span>
                    </li>
                </ul>
            </div>
        </div>
        </div>
    );
}



