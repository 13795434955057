import React from "react";
import './home.css';
import {useTypewriter,Cursor} from'react-simple-typewriter'
import img1 from './sid2.jpeg'

export default function Home() {
    const [text] = useTypewriter({
        words:['Web Developer!','IOT Student','ECE Engineer','UI&UX designer'],
        loop:true,
        typeSpeed:100,
        delaySpeed:1000,
        deleteSpeed: 75
    })
    return (
        <div className="total">
            <div className="left-side">
                {/* <h1></h1> */}
                <h2> Hi! This is Siddardha Ladi!</h2>
                <h2>I'M a {' '} 
                    <span>
                      {text}
                    </span> 
                    <span> 
                      <Cursor/>
                    </span> 
                </h2>
                <p>
                    I am Siddardha Ladi, pursuing a B.Tech in the final year at
                    Aditya Institute of Technology and Management (A.I.T.A.M).
                    Skilled in Web Development, IoT, and Problem Solving.
                    Completed an internship at Repsoft Consultancy Private Limited, Hyderabad,
                    focusing on Front End Web Development.
                </p>
            </div>
            <div className="right-side">
                <img src={img1} alt="Profile" />
            </div>
            
        </div>
    );
}
